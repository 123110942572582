@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&family=Poppins:wght@200&display=swap');
:root{
  --bg: #23272e;
  --dark: #333;
  --white: #fafafa;
  --bluegray: #9d9fcc;  
}

@import '~antd/dist/antd.css';


body{
  overflow: hidden;
}

body,
ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;    
}
.list > ul > li {
  width: calc(100% / 2);
  color: #fff;
  font-size: 11pt;  
  padding: 10px;
}


#root{
  height:100%;
  overflow: hidden;
}

.App{
  text-align: center;    
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 600px;
  font-size: 100%;
  margin: auto;
  overflow: auto;
  background: rgb(73,93,140);
  background: linear-gradient(180deg, rgba(73,93,140,1) 0%, rgba(45,52,69,1) 100%);
}

.header-logo{
  height: 150%;
  top: -10px;
  position: relative;
}

.membership-package{
  color:#fff;
  font-size: 1.5rem;  

}

.Connect-to-TronLink {
  width: 296px;
  height: 23px;
  font-family: 'Poppins', sans-serif;
  text-shadow: 0 0 6px #fff;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fafafa;
  cursor: pointer;
}

.Connect-to-TronLink.dev{
  color: #00ff00;
  cursor: pointer;
}

.page-title{
  width: 100%;
  height: 50px;
  margin: 30px 12.4px 20px 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: left;
  color: #d8bd99;
}

#header{
  flex: 1;
  max-height: 60px;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  background-color: #1c1f24;
  letter-spacing: 2px;
  font-size: 16px;
  color: var(--white);
  margin-bottom: 20px;
  text-align: center;
}

#header>div{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
#header .title{
  letter-spacing: 8px;
  text-shadow: 0 0 6px #fff;
  font-size: 20px;
  cursor: pointer;
}

#header .title.dev{
  color: #00ff00;
}

#nav{
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(9, 101, 117);
  bottom: 0;
  width: 100%;
  max-width: 600px;
  padding: 20px 0;
}
#nav .link{
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
}
#nav .active-link{
  /* flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  text-decoration: none;
  font-size: 10px; */
  color: #fff;
}
#nav .link svg{
   width: 32px;
}

#nav .active-link svg{
  fill: #fff;
}

#nav .non-link{
  /* flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  color: #aef4fd;
   /* text-decoration: none;
  font-size: 10px; */
}
#nav .non-link svg{
  fill:  #aef4fd;
}
/* 
.my-creta-info-wallet{
  display: flex;
  justify-content:  start;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: left;
  color: #fafafa;
  padding-bottom: 10px;
  margin-bottom: 0px;
  margin-top:10px;
}
.my-creta-info-wallet .address{
  overflow: hidden;
  text-overflow: ellipsis;  
  word-break: break-all;
}
.my-creta-info-wallet .address .btn{  
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #8f776d;
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
    height: auto;
    text-align:left;
    background-color: transparent;  
}
.my-creta-info-wallet .address .btn-edit{  
  margin-left: 5px !important;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: #4d4d4d;
  color: rgb(219, 216, 216);
  letter-spacing: 1px;
  font-size: 12px;
}
.my-creta-info-wallet .info-wallet{
  font-size: 40%;
  color: gray;
  font-family: 'Poppins', sans-serif;
  display:flex;
  margin-top : 15px;
}

.my-creta-info-wallet .btn{
  height: 30px;
}

.my-info-wrap{
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: left;
  color: #fafafa;
  margin-bottom: -18px;
}
.my-info-wallet .address{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.my-info-wallet{
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: left;
  color: #fafafa;
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.my-info-wallet .info-wallet{
  font-size: 40%;
  color: gray;
  font-family: 'Poppins', sans-serif;
  display:inline-flex;
  margin-top : 15px;
} */

.content-title{
  color: rgb(236, 234, 234);
  text-align:left;
  box-shadow: 3px 3px 6px 0 rgba(26, 26, 26, 0.49), -3px -3px 6px 0 rgba(54, 54, 54, 0.1);
  background-color: #3b4049;
  border-radius: 6px;
  padding:10px;
  margin-bottom: 20px;
}

.content-title.main{
  background-size: cover;
}

.content-package{
  color: #F1C40F;
  text-align:left;
  box-shadow: 3px 3px 6px 0 rgba(26, 26, 26, 0.49), -3px -3px 6px 0 rgba(54, 54, 54, 0.1);
  background-color: #2e2f3f;
  border-radius: 6px;
  padding:10px;
  margin-bottom: 20px;
  font-size: 14pt;
}

.content-bottom{
  color: #F1C40F;
  text-align:left;
  box-shadow: 3px 3px 6px 0 rgba(26, 26, 26, 0.49), -3px -3px 6px 0 rgba(54, 54, 54, 0.1);
  background-color: #2e2f3f;
  border-radius: 6px;
  padding:10px;
  margin-bottom: 20px;
}

/* .dashboard-box{
  width: 100%;  
  padding: 15px 15px 0px 15px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 3px 3px 6px 0 rgba(26, 26, 26, 0.49), -3px -3px 6px 0 rgba(54, 54, 54, 0.1);
  background-color: #3b4049;
} */
/* .dashboard-box .summary{  
  color: #868686 ;
  font-size: 15px;
  padding: 10px;
  text-align: right;
  border-top: 1px dashed #565757;
  font-family: 'Noto Sans KR', sans-serif;
} */

/* .dashboard-box .summary strong{
  color: #d8bd99;
  letter-spacing: 1px;
}
.dashboard-box .summary strong span.usdt{
  letter-spacing: 0.9px;
}
.dashboard-box .summary strong span.creta{
  letter-spacing: -0.5px;
} */
/* 
.dashboard-box-invest{
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: -15px;
  border-radius: 6px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49), -3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #23272e;
  color: #fff;
}
.dashboard-box-invest>div:first-child{
  margin-bottom: 10px;
}
.dashboard-box-invest>div{
  display: grid;
  grid-template-columns: 150px auto;
  align-items: center;
  text-align: left;
}
.dashboard-box-invest p{
  margin: 0;
} */

.dashboard-box-message{
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 0px;
  border-radius: 6px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49), -3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #CA6F1E;
  font-weight: normal;  
}

.dashboard-box-message .message{
  margin-top: 50px;
  font-weight: normal;
  color: #fff;
  text-align: left;
  font-size: 12pt;
}

input::placeholder {
  color: hsl(0, 0%, 50%) !important;
}

.dashboard-child{
  width: 100%;
  height: 130px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49), -3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #23272e;
}

.help-center{
  color:gainsboro;
  text-align: right;
  padding-right: 30px;
}

.help-center>a{
  font-size: 70% !important; 
  text-decoration:none;
  color: gainsboro;
}

.help-center>p{
  font-size: 70% !important;
  margin-top: 5px !important;
}

.info-text{
  display: inline-block;
}

/* .deposit{
  margin-top : 10px;
  font-size : 15px;
  color: #d8bd99;
} */

.btn-confirm{
  display: block;
  min-width: 140px;
  height: 35px;
  border-radius: 6px;  
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49), -3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #915d48;  
  letter-spacing: 1px;
  font-size : 15px;
  color:  #d9d9d9;
  font-family: 'Noto Sans KR', sans-serif;
  float:right;
}

.text-right{
  text-align: right;
  color:  #d9d9d9;
}

/* 
.btn-referral-list{
  display: block;
  min-width: 140px;
  height: 35px;
  border-radius: 6px;
  border-color:#23272e;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49), -3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #23272e;
  letter-spacing: 1px;
  font-size : 15px;
  color:  #d9d9d9;
  font-family: 'Noto Sans KR', sans-serif;
  float:right;
}

.mining-package{
  font-size: 24px;
} */

.loading-wrap{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(20,20,20,0.8);
}

.loading-text{
  color: rgba(255, 255, 255, 0.9);
  
}

.loading-small-wrap{  
  display: flex;
  justify-content: center;
  align-items: center; 
  margin:20px;
}

/* .register-form{
  width: 90%;
  max-width: 400px;
  margin: auto;
} */

.form-group {
  width: 100%;
  margin: 25px auto;
}

/* .form-group>input{
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: solid 0.5px #dbdbdb;
  background-color: rgb(255, 255, 255);
  padding: 5px 8px;
  color: var(--white);
} */

.referral-list{
  font-size: 150%;
}

.referral-map{
  color: white;
  font-size: 90%;
}


#history{
  min-height: 100vh;
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 2px;
}
.ReactModal__Content{
  padding: 45px !important;
  padding-bottom: 70px !important;
  transition: 800ms ease-in-out;
  inset: 0 !important;
  font-family: 'Noto Sans KR', sans-serif !important;
  border-radius: 0px 0px 0 0 !important;
  background-color: #23272e !important;
  border: none !important;
  color: #d8bd99 !important;
  overflow: auto !important;
  top: 100% !important;
  max-width: 800px;
  margin: -50px auto;
  text-align: center;
  letter-spacing: 1px;
  
}

.ReactModal__Content--after-open{
  top: 40% !important;
  box-shadow:  0 -5px 20px 0 rgba(0, 0, 0, 0.7) !important;
}
.ReactModal__Content--before-close{
  top: 100% !important;
  box-shadow:  0 -5px 20px 0 rgba(0, 0, 0, 0.7) !important;
}
.ReactModal__Overlay--after-open{
  background-color: rgba(20,20,20,0.8) !important;
}
.ReactModal__Overlay {
  opacity: 0 !important;
  transition: opacity 200ms ease-in-out !important;
}
.ReactModal__Overlay--after-open{
  opacity: 1 !important;
}
.ReactModal__Overlay--before-close{
  opacity: 0 !important;
}

.history-table{
  width: 100%;
  text-align: center;
  font-size: 20%;
  border-spacing: 0;
  color: #dfe0f5;
}
.history-table thead{
  background-color: black;
}
.history-table thead th{
  padding: 12px 5px;
  font-weight: normal;
}
.history-table tbody td{
  padding: 15px 0 ;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);;
}


.view-info{
  margin: 40px auto 15px auto;
  width: 60%;
  padding: 15px;
  border: none;    
  color: var(--white);
  letter-spacing : 2px;
  font-size: 12px;
  border-top: solid 1px rgba(255, 255, 255, 0.08);
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  margin-bottom: 50px;
}


.view-more{
  margin: 40px auto 15px auto;
  width: 60%;
  padding: 15px;
  border-radius: 6px;
  border: solid 1px rgba(255, 255, 255, 0.08);
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49), -3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #23272e;
  color: var(--white);
  letter-spacing : 2px;
  font-size: 12px;
  margin-bottom: 50px;
}
.view-more:hover{
  color:rgb(250, 211, 37);
}

.react-tabs{
  margin-top: 10px;
  
}
.react-tabs__tab-list{
  display: flex;
  text-align: center;
  margin: 0 !important;
  border: none !important;
  border-radius: 6px;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 20%;
  background-color: var(--bg);
}
.react-tabs__tab{ 
  width: 100%;
  color: #77778b;
  background-color: var(--bg);
}
.react-tabs__tab--selected{
  background-color: black !important;
  color: #fff !important;
  border: none !important;
}
.main-container{
  width: 100%;  
  height:100%;
  overflow: hidden;  
  margin-bottom: 50px;
}
.sub-container{
  width: 100%;  
  height:100%;
  overflow: auto;  
  padding-top:5px;
  
}
.content{
  display: flex;
  align-items: left;
  flex-direction: column;
  padding: 0 15px;
  font-size: 14px;
  margin-bottom : 50px;
}

#history>div{
  width: 100%;
}

/* .timeset{
  color: #fff;
  font-size: 11px;
  margin-bottom: 18px;
  margin-top : -10px;
} */

.hisTitle{
  width: 100%;
  height: 32px;
  margin: 20px 16px;
  padding-left: 15px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: left;
  color: #d8bd99;
}

/* org chart node style */
/* .node rect{
  fill: rgba(255, 255, 255, 0.2);
  stroke: rgba(255, 255, 255, 0.7);
}
.node text{ 
  font-size: 16px;
} */
/* 
.notice-header{
  color: var(--white);
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.notice-header .date{
  text-align: right;
  font-size: 14px;
}
.notice-header .title{
  font-weight: bold;
  font-size: 16px;
}
.notice-body{
  color: var(--white);
  font-size: 14px;
  text-align: left;
}

.mining-header{
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 25px;
} */



/* .list > ul > li {
  padding: 0 10px;
}
.list > ul {
  margin: 0 -10px;
} */

.list > ul > li > .card {
  background-color: #16171b;
  box-shadow: 3px 3px 6px 0 rgb(26 26 26 / 49%), -3px -3px 6px 0 rgb(54 54 54 / 10%);  
  padding: 0px;  
  border-radius: 5%;  
}

.list > ul > li > .card .img-box img{
  width: 100%;
  border-radius: 5% 5% 0% 0%;
}

.list > ul > li  .product-name {
  text-align: Center;  
  padding-top: 20px;
  vertical-align: bottom !important;
  height: 80px;
  line-height: 99%;
  font-size: 16px;
}
.list > ul > li:hover > .product-name {
  text-decoration: underline;
}
.list > ul > li > .product-price {
  text-align: center;
  font-weight: bold;  
  font-size: 20px;
}
.list > ul > li  .product-buttons {
  text-align: center;
  font-weight: bold;  
}

.list > ul > li  .product-buttons .transparent {  
  visibility: hidden;
}


.btn-buy, .btn-buy:hover, .btn-buy:focus, .btn-buy:active{
  width: 40%;
  height: 30px;
  padding: 3px;
  border-radius: 6px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.49),-3px -3px 6px 0 rgba(255, 255, 255, 0.1);
  background-color: #005fb1;
  color:#aef4fd;
  margin: 20px 10px;
  letter-spacing: 1px;
  font-size : 14px;  
}
.btn-buy[disabled], .btn-buy[disabled]:hover, .btn-buy[disabled]:focus, .btn-buy[disabled]:active {
  color: #5a5a5a;
  border-color: #757272;
  background: #353535;
  text-shadow: none;
  box-shadow: none;
}

.btn-buy {
  border: none;  
  resize: none;
  outline: none;
}


/* .list > ul > li > .product-price::after {
  content: "원";
  font-size: 1rem;
  font-weight:normal;
} */


@media (max-width: 800px) {
  .list > ul > li {
      width: calc(100% / 2);
  }
}

@media (max-width: 650px) {
  .list > ul > li {
      width: calc(100% / 2);
  }
}

@media (max-width: 500px) {
  .list > ul > li {
      width: calc(100% / 2);
  }
}

@media (max-width: 400px) {
  .list > ul > li {
      width: calc(100% / 1);
  }
}

@media (max-width: 300px) {
  .list > ul > li {
      width: calc(100% / 1);
  }
}

.ReactModal__Content--after-open.troonLinkModal {
  top: 60% !important;
  box-shadow: 0 -5px 20px 0 rgb(0 0 0 / 70%) !important;

  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.ReactModal__Content--after-open.paymentModal {
  top: 10% !important;
  box-shadow: 0 -5px 20px 0 rgb(0 0 0 / 70%) !important;

  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}


/* .ReactModal__Content--after-open {
  top: 10% !important;
  box-shadow: 0 -5px 20px 0 rgb(0 0 0 / 70%) !important;
} */


.content-terms{
  text-align: left;
  color: #fff;
}
.content-terms .title{
  color: #D8BD99;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.content-terms li{
  margin-bottom: 20px;
}

.content-terms li dt{
  margin-bottom: 5px;
}
.content-terms li dd{
  color:#ccc
}

.payment-iframe{
  width: 94%;
  height:90%;
  position: absolute;
  top:10px;
  left:0px;
  background-color: #fff;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 30px;
  text-align: center;  
}

.App-payment-noscroll{
  padding-bottom: 0px;
  overflow:hidden;
}
.App-payment{
  padding-bottom: 300px;
}

.App-payment h1{
  color: #fff;
}

.App-payment h4{
  color: #fff;
  font-size: 14px;
}

.App-payment .payable-amount{  
  font-size: 20px !important;
  color: #D8BD99 !important;  
}
.App-payment .btn{
  margin: 10px;
}